<template>
  <b-overlay :show="showOverlay">
    <div class="m-2">
      <arq-previews-page />
      <h1>Simulación De Financiación</h1>
      <form
        v-if="!verTabla"
        class="col col-md-6 col-sm-2 mx-auto  my-4"
      >
        <div class="card p-2">
          <div
            v-for="(f, index) in form"
            :key="index"
          >
            <div :class="f.hidden ? 'd-none' : ' '">
              <label :for="f.key">
                {{ f.label }}
              </label>
              <div v-if="types.includes(f.type)">
                <div v-if="f.key.includes('identification')">
                  <b-form-input
                    :id="f.key"
                    v-model="data[f.key]"
                    :name="f.key"
                    :type="f.type"
                    :required="f.required"
                    :disabled="f.disabled"
                    @input="getContract(data[f.key])"
                  />
                </div>
                <div>
                  <div v-if="f.key.includes('nummaxcuotas')">
                    <b-form-input
                      :id="f.key"
                      v-model="data[f.key]"
                      :name="f.key"
                      :type="f.type"
                      :required="f.required"
                      :disabled="validateSaldo"
                      @input="keyValidateDues(data[f.key])"
                    />
                  </div>
                  <div v-if="f.key.includes('cuotainicial')">
                    <b-form-input
                      :id="f.key"
                      v-model="data[f.key]"
                      :name="f.key"
                      :type="f.type"
                      :required="f.required"
                      :disabled="validateSaldo"
                      @input="keyValidateBalanceMin(data[f.key])"
                    />
                  </div>
                </div>
                <div
                  v-if="
                    !f.key.includes('nummaxcuotas') &&
                      !f.key.includes('identification') &&
                      !f.key.includes('cuotainicial')
                  "
                >
                  <b-form-input
                    :id="f.key"
                    v-model="data[f.key]"
                    :name="f.key"
                    :type="f.type"
                    :required="f.required"
                    :disabled="f.disabled"
                  />
                </div>
              </div>
              <div v-if="f.type === 'select-contract'">
                <b-form-select
                  :id="f.key"
                  v-model.number="data[f.key]"
                  value-field="idProducto"
                  text-field="descservicio"
                  :options="contratos"
                  @input="getCarteraSimulacro(data[f.key])"
                />
                <div class="my-2">
                  <span>Saldo Cartera:</span>
                  {{ consultaCartera.balance | toCurrency }}
                </div>
              </div>
              <div v-if="f.type === 'select-services'">
                <b-form-select
                  :id="f.key"
                  v-model.number="data[f.key]"
                  value-field="idProducto"
                  text-field="descservicio"
                  :options="optionsServices"
                />
              </div>
            </div>
          </div>
          <b-button
            :disabled="validateButton"
            variant="primary mt-3"
            @click="confirm()"
          >
            Confirmar
          </b-button>
        </div>
      </form>
      <!-- Card Info -->
      <b-card
        v-if="verTabla"
        no-body
        class="col col-md-6 col-sm-2 mx-auto  my-4"
      >
        <b-button
          variant="primary my-2 "
          @click="verTabla = false"
        >
          Consultar otro contrato
        </b-button>
        <b-card-header>
          <!-- <b-card-title>Simulación De Financiación</b-card-title> -->
        </b-card-header>
        <b-card-body class="statistics-body">
          <b-row>
            <!-- v-for="item in statisticsItems" -->
            <!-- :key="item.icon" -->
            <b-col
              md="3"
              sm="12"
              lg="4"
              class="mb-2 mb-md-0"
            >
              <!-- :class="item.customClass" -->
              <b-media no-body>
                <b-media-aside class="mr-2">
                  <b-avatar
                    size="48"
                    variant="light-primary"
                  >
                    <feather-icon
                      size="24"
                      icon="DollarSignIcon"
                    />
                  </b-avatar>
                </b-media-aside>
                <b-media-body class="my-auto">
                  <h4 class="font-weight-bolder mb-0">
                    Saldo Cartera
                  </h4>
                  <b-card-text class="font-small-3 mb-0">
                    {{ consultaCartera.balance | toCurrency }}
                  </b-card-text>
                </b-media-body>
              </b-media>
            </b-col>
            <b-col
              md="3"
              sm="12"
              lg="4"
              class="mb-2 mb-md-0"
            >
              <!-- :class="item.customClass" -->
              <b-media no-body>
                <b-media-aside class="mr-2">
                  <b-avatar
                    size="48"
                    variant="light-success"
                  >
                    <feather-icon
                      size="24"
                      icon="CreditCardIcon"
                    />
                  </b-avatar>
                </b-media-aside>
                <b-media-body class="my-auto">
                  <h4 class="font-weight-bolder mb-0">
                    Numero De Cuotas
                  </h4>
                  <b-card-text class="font-small-3 mb-0">
                    {{ consultaCartera.dues }}
                  </b-card-text>
                </b-media-body>
              </b-media>
            </b-col>
            <b-col
              md="3"
              sm="12"
              lg="4"
              class="mb-2 mb-md-0"
            >
              <!-- :class="item.customClass" -->
              <b-media no-body>
                <b-media-aside class="mr-2">
                  <b-avatar
                    size="48"
                    variant="light-info"
                  >
                    <feather-icon
                      size="24"
                      icon="DollarSignIcon"
                    />
                  </b-avatar>
                </b-media-aside>
                <b-media-body class="my-auto">
                  <h4 class="font-weight-bolder mb-0">
                    Cuota Inicial
                  </h4>
                  <b-card-text class="font-small-3 mb-0">
                    {{ consultaCartera.balanceMin | toCurrency }}
                  </b-card-text>
                </b-media-body>
              </b-media>
            </b-col>
          </b-row>
        </b-card-body>
        <div class="my-4">
          <b-table
            responsive="md"
            :items="items"
            class="w-100 ml-auto mr-auto"
          >
            <template #cell(SaldoInicial)="data">
              <span class="text-nowrap">
                {{ data.value | toCurrency }}
              </span>
            </template>
            <template #cell(CuotaTotal)="data">
              <span class="text-nowrap">
                {{ data.value | toCurrency }}
              </span>
            </template>
            <template #cell(Abono)="data">
              <span class="text-nowrap">
                {{ data.value | toCurrency }}
              </span>
            </template>
            <template #cell(SaldoFinal)="data">
              <span class="text-nowrap">
                {{ data.value | toCurrency }}
              </span>
            </template>
          </b-table>
        </div>
      </b-card>
      <b-modal
        id="modal-confirm-process"
        :title="response.title"
        ok-only
      >
        <div>
          <b-alert
            :variant="response.variant"
            show
          >
            {{ response.message }}
          </b-alert>
        </div>
      </b-modal>
    </div>
  </b-overlay>
</template>

<script>
import axios from 'axios'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  BCard,
  BCardHeader,
  BCardText,
  BCardBody,
  BRow,
  BCol,
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,
} from 'bootstrap-vue'
import { mapState } from 'vuex'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      verTabla: false,
      data: {},
      response: {},
      items: [],
      showOverlay: false,
      form: [
        {
          label: 'Identificación',
          key: 'identification',
          type: 'number',
          required: true,
          hidden: true,
        },
        {
          label: 'Contrato',
          key: 'idContract',
          type: 'select-contract',
          required: true,
        },
        {
          label: 'Número de cuotas',
          key: 'nummaxcuotas',
          type: 'number',
          required: true,
        },
        {
          label: 'Cuota Inicial',
          key: 'cuotainicial',
          type: 'number',
          required: true,
        },
      ],
      types: [
        'text',
        'number',
        'email',
        'password',
        'search',
        'url',
        'tel',
        'date',
        'time',
        'range',
        'color',
      ],
      consultaCartera: {
        balance: '',
        balanceMin: '',
        dues: '',
      },
      validateButton: false,
      validateSaldo: false,
    }
  },
  mounted() {
    this.data.identification = this.$store.state.client.client.identificacion
  },
  computed: {
    ...mapState('client', ['contratos']),
  },
  methods: {
    confirm() {
      this.showOverlay = true
      this.verTabla = false
      this.data.idplanfinan = 50
      this.sendProcedure(this.data).then(res => {
        this.verTabla = true
        if (res.data.code >= 0) {
          this.items = res.data.list.map(el => ({
            CodigoSimulación: el.idsimulacion,
            Meses: el.numes,
            SaldoInicial: el.nusaldoinicial,
            CuotaTotal: el.nucuotatotal,
            Interes: el.nuvalinteres,
            Abono: el.nuabonocapital,
            SaldoFinal: el.nusaldofinal,
          }))
        }
        if (res.data.code < 0) {
          this.response.title = '¡Alerta!'
          this.response.message = res.data.msg
          this.response.variant = 'danger'
          this.$bvModal.show('modal-confirm-process')
        }
      })
    },
    async sendProcedure(formData) {
      this.showOverlay = true
      return new Promise((resolve, reject) => {
        axios
          .post('/app/procedure/simulacroFinanciacion', formData)
          .then(res => {
            resolve(res)
          })
          .catch(err => {
            reject(err)
          })
          .finally(() => {
            this.showOverlay = false
          })
      })
    },
    getCarteraSimulacro(id) {
      if (id) {
        this.showOverlay = true
        axios
          .get(`/client/consultaCarteraSimulacro/${id}`)
          .then(res => {
            if (res.data.balance === 0) {
              this.$bvModal.show('modal-confirm-process')
              this.response.message = 'El saldo de la cartera es 0 por lo tanto no puede continuar con la simulación'
              this.response.variant = 'warning'
              this.response.title = '¡Alerta!'

              this.validateButton = true
              this.validateSaldo = true
            } else {
              this.validateButton = false
              this.validateSaldo = false
            }
            this.consultaCartera = res.data
            this.data.nummaxcuotas = this.consultaCartera.dues
            this.data.cuotainicial = this.consultaCartera.balanceMin
          })
          .finally(() => {
            this.showOverlay = false
          })
      }
    },
    keyValidateDues(dues) {
      if (dues > this.consultaCartera.dues) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Mensaje',
            icon: 'AlertOctagonIcon',
            text: `No puedes ingresar un numero de cuotas mayor a ${this.consultaCartera.dues}`,
            variant: 'warning',
          },
        })
        this.validateButton = true
        this.data.nummaxcuotas = this.consultaCartera.dues
      } else {
        this.validateButton = false
      }
    },
    keyValidateBalanceMin(balanceMin) {
      if (balanceMin < this.consultaCartera.balanceMin) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Mensaje',
            icon: 'AlertOctagonIcon',
            text: `No puedes ingresar una cuota inicial menor a ${this.consultaCartera.balanceMin}`,
            variant: 'warning',
          },
        })
        this.validateButton = true
        this.data.cuotainicial = this.consultaCartera.balanceMin
      } else {
        this.validateButton = false
      }
    },
  },
}
</script>

<style>
/* .statistics-body {
position: relative;
margin-left: -10em !important;
} */
</style>
